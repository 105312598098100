<template>
    <v-stepper v-model="steps" vertical style="box-shadow: none">
        <v-row v-for="(step, index) in stepperData" :key="`stepper-${index}`">
            <v-col cols="12" class="pa-0 mx-0 my-1">
                <v-stepper-header evelation="0">
                    <v-stepper-step
                        style="width: 100%"
                        :color="(steps > step.pos ? getColors.secondary2 : getColors.secondary1)"
                        :editable="step.editable()"
                        edit-icon="mdi-check"
                        :complete="step.completed()"
                        :step="step.pos"
                    >
                        {{ step.title }}
                    </v-stepper-step>
                </v-stepper-header>
                <v-stepper-content
                    class="pa-0 ma-0"
                    :step="step.pos"
                >
                    <v-card
                        class="ma-4"
                        elevation="0"
                        min-height="300"
                    >
                        <component v-bind:is="step.component"/>
                        <v-divider/>
                        <v-card-actions>
                            <v-row class="my-4">
                                <v-col cols="auto">
                                    <v-btn
                                        text
                                        :disabled="!step.requirementCancel()"
                                        :color="getColors.error"
                                        @click="$store.commit('SET_DIALOG', {state: true, data: step})"
                                    >
                                        {{ $t('common.words.cancel') }}
                                    </v-btn>
                                </v-col>
                                <v-spacer/>
                                <v-col cols="auto">
                                    <v-btn
                                        text
                                        :loading="$store.getters.getLoaders.jobCreator"
                                        :disabled="!step.requirementNext()"
                                        :color="getColors.success"
                                        @click="$emit('continue-action', $event, step.pos)"
                                    >
                                        {{ step.continueBtnText(step.pos) }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>
            </v-col>
        </v-row>
    </v-stepper>
</template>

<script>
import { mapGetters } from 'vuex'
import SelectChannelStep from '@/components/Uebertragungserstellungsassistent/SchnittstelleAuswaehlen.vue'
import SelectMembersStep from '@/components/Uebertragungserstellungsassistent/KundenAuswaehlen.vue'
import SelectPropertiesStep from '@/components/Uebertragungserstellungsassistent/ObjekteFuerJedenKundenAuswaehlen.vue'
import SelectJobStep from '@/components/Uebertragungserstellungsassistent/AufgabenartAuswaehlen.vue'
import SummaryExecuteStep from '@/components/Uebertragungserstellungsassistent/Aufgabenzusammenfassung.vue'

export default {
    name: 'StepperVertical',
    props: {
        stepperData: Array
    },
    components: {
        SelectChannelStep,
        SelectMembersStep,
        SelectPropertiesStep,
        SelectJobStep,
        SummaryExecuteStep,
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getStep',
        ]),
        steps: {
            get() {
                return this.getStep
            },
            set(newValue) {
                this.$store.commit('SET_STEP', newValue)
            },
        },
    },
}
</script>
