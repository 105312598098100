<template>
    <div>
        <v-card-title
            class="pl-0"
            v-html="$t('jobCreator.stepper.step.property.heading', {name: getJobCreation.textid})"
        />
        <v-card-subtitle
            class="pl-0"
            v-html="$t('jobCreator.stepper.step.property.subheading', {name: getJobCreation.textid})"
        />
        <v-card class="elevation-0 pl-0">
            <div
                v-for="(member, index) in getJobCreation.members"
                :key="`memberindex-${index}`"
                :class="(Object.keys(getJobCreation.members)[Object.keys(getJobCreation.members).length - 1] !== index ? 'mb-4' : 'mb-0')"
            >
                <properties-combobox
                    :component-index="Object.keys(getJobCreation.members).indexOf(index)"
                    :member="member"
                    :memberid-index="parseInt(index)"
                    ref="member"
                />
            </div>
        </v-card>
    </div>
</template>

<script>
import PropertiesCombobox from '@/components/Uebertragungserstellungsassistent/ObjekteAuswaehlen.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'SelectPropertiesStep',
    components: {
        PropertiesCombobox,
    },
    computed: {
        ...mapGetters([
            'getJobCreation',
            'getChannelProperties',
            'getChips',
            'getStep',
        ]),
    },
    watch: {
        '$store.state.step': async function () {
            if (this.getStep === 3 && Object.keys(this.getChips).length !== Object.keys(this.getChannelProperties).length)
                await this.getAndSetChannelProperties()
        },
        '$store.state.chips': async function () {
            if (Object.keys(this.getChips).length > Object.keys(this.getChannelProperties).length)
                await this.getAndSetChannelProperties()
        },
    },
    methods: {
        async getAndSetChannelProperties() {

            const memberids = []
            const chips = this.getChips

            for (let memberid in chips) {
                if (chips.hasOwnProperty(memberid) && memberid > 0)
                    memberids.push(memberid)
            }

            await this.$store.dispatch('getAndSetChannelPropertiesMultipleMembers', {
                ssid: this.getJobCreation.ssid,
                memberids: memberids,
            })
        },
    },
    async mounted() {
        if (this.getStep === 3) {
            if (Object.keys(this.getChips).length !== Object.keys(this.getChannelProperties).length)
                await this.getAndSetChannelProperties()
        }
    },
}
</script>
