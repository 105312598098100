<template>
    <div>
        <v-card flat>
            <v-card-title
                class="pl-0"
                v-html="$t('jobCreator.stepper.step.member.heading', { name: getJobCreation.textid })"
            />
            <v-card class="elevation-1 pa-4" tile>
                <v-combobox
                    class="w-80-max"
                    v-model="chips"
                    ref="comboField"
                    :items="items"
                    :search-input.sync="search"
                    @change="search = ''"
                    :loading="getLoaders.channelmembers"
                    :color="getColors.primary"
                    :item-color="getColors.primary"
                    :placeholder="placeholderText"
                    :label="labelText"
                    @focus="labelText = ''"
                    @blur="(!Object.keys(getChips).length ? labelText = placeholderText : labelText = '')"
                    chips
                    deletable-chips
                    clearable
                    @click:clear="clearAllChips"
                    multiple
                >
                    <template v-slot:selection="data">
                        <v-chip
                            v-if="data.item.value"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            :color="getColors.primary"
                            text-color="white"
                            @click:close="removeMember(data.item)"
                            close
                        >
                            <span>
                                {{ JSON.parse(JSON.stringify(data.item)).text }}
                            </span>
                        </v-chip>
                    </template>
                </v-combobox>
            </v-card>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'SelectMemberStep',
    data() {
        return {
            items: [],
            search: null,
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getMembers',
            'getLoaders',
            'getJobCreation',
            'getStep',
            'getChips',
            'getChannelMembers',
        ]),
        chips: {
            get: function () {
                const chips = this.getChips

                if (!Object.keys(chips).length)
                    return []

                const chipsArr = []

                for (let i in chips) {
                    if (chips.hasOwnProperty(i)) {
                        const chip = chips[i]

                        if (!chipsArr.includes(chip.member))
                            chipsArr.push(chip.member)
                    }
                }

                return chipsArr
            },
            set: function (selectedMemberArr) {

                const members = {}

                selectedMemberArr.forEach(val => {
                    const memberid = val.value

                    if (!members.hasOwnProperty(memberid)) {
                        members[memberid] = {
                            member: val,
                            properties: [],
                        }
                    }
                })

                if (Object.keys(members).length) {
                    this.$store.commit('SET_CHIPS', members)

                    this.$store.commit('UPDATE_JOB_CREATION', {
                        members: members,
                    })
                }
            },
        },
        placeholderText() {
            return this.getLabelAndPlaceholderText()
        },
        labelText: {
            get: function () {
                return this.getLabelAndPlaceholderText()
            },
            set: function (newVal) {
                return newVal
            },
        },
    },
    watch: {
        '$store.state.step': async function () {
            if (this.getStep === 2) {
                this.$refs.comboField.focus()
                await this.$store.dispatch('getAndSetChannelMembers', this.getJobCreation.ssid)
                this.$store.commit('SET_CHANNEL_MEMBERS', this.getMembers)
                this.createComboboxItems()
            }
            else if (this.getStep < 2) {
                this.items.splice(0, this.items.length)
                this.$store.commit('RESET_CHANNEL_MEMBERS')
            }
        },
    },
    methods: {
        getLabelAndPlaceholderText() {
            if (!this.getChannelMembers.length)
                return ''

            return this.$i18n.tc('jobCreator.stepper.step.member.comboboxPlaceholder', this.getChannelMembers.length, { name: this.getJobCreation.textid })
        },
        createComboboxItems() {
            this.getChannelMembers.forEach(val => {
                this.items.push({
                    text: `${ val.id } - ${ val.firstname } ${ val.name }, ${ val.company }`,
                    value: val.id,
                })
            })
        },
        clearAllChips() {
            this.$store.commit('UPDATE_JOB_CREATION', { members: {} })
            this.$store.dispatch('resetProps', 'chips')
        },
        removeMember(item) {
            this.$store.commit('REMOVE_FROM_CHIPS', { memberid: item.value })
        },
    },
    mounted() {

        if (this.getStep === 2) {
            if (this.getChannelMembers.length)
                this.createComboboxItems()
        }
    },
}
</script>
