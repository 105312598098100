<template>
    <v-card class="pa-4" elevation="0">
        <v-card-title
            class="pl-1"
            v-html="$tc('jobCreator.stepper.step.summary.heading', getJobsTotalCount(), {job: getJobCreation.job})"
        />
        <v-card
            v-for="(jobCreation, memberidAsKey) in getJobCreation.members"
            :key="`jobCreation-${memberidAsKey}`"
            :class="(Object.keys(getJobCreation.members)[Object.keys(getJobCreation.members).length - 1] !== memberidAsKey ? 'mb-4' : 'mb-0') + ' pa-4'"
            tile
            elevation="1"
        >
            <v-row>
                <v-col cols="1" class="pt-5">
                    <v-icon large :color="getColors.success">
                        mdi-check-circle-outline
                    </v-icon>
                </v-col>
                <v-col cols="11">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>
                                <span class="font-weight-medium">
                                    {{ jobCreation.member.text }}
                                </span>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                <span
                                    class="font-weight-medium pr-1"
                                    v-html="`${$tc('common.words.propertiesWithNumber', getJobCreation.success.members[memberidAsKey].results.length)}:`"
                                />
                                <v-tooltip
                                    v-for="(result, resultIndex) in getJobCreation.success.members[memberidAsKey].results"
                                    :key="`property-${resultIndex}`"
                                    bottom
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-chip
                                            style="cursor: pointer"
                                            class="my-2 mr-2"
                                            :color="getColors.success"
                                            text-color="#fff"
                                            small
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            {{ result.objid }}
                                        </v-chip>
                                    </template>
                                    <span>
                                        Insert ID: {{ result.insertid }}
                                    </span>
                                </v-tooltip>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
        </v-card>
    </v-card>
</template>

<script>
import TTCustom from '@/components/Tooltips/TTFreierText.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'SuccessCard',
    components: {
        TTCustom,
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getJobCreation',
        ]),
    },
    methods: {
        getJobsTotalCount() {
            const members = this.$store.getters.getJobCreation.members
            let count = 0

            for (let i in members) {
                if (members.hasOwnProperty(i)) {
                    const member = members[i]
                    count += member.properties.length
                }
            }

            return count
        },
    },
}
</script>
