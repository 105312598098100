<template>
    <v-card class="pa-4" elevation="0">
        <v-card-title class="pl-1" v-html="$t('jobCreator.stepper.step.execute.heading', {channelName: getJobCreation.channelName, jobName: getJobCreation.job})"/>
        <v-card
            v-for="(jobCreation, memberidAsKey) in getJobCreation.members"
            :key="`jobCreation-${memberidAsKey}`"
            :class="(Object.keys(getJobCreation.members)[Object.keys(getJobCreation.members).length - 1] !== memberidAsKey ? 'mb-4' : 'mb-0') + ' pa-4'"
            tile
            elevation="1"
        >
            <v-list-item two-line>
                <v-list-item-content>
                    <v-list-item-title>
                        <span class="font-weight-medium">
                            {{ jobCreation.member.text }}
                        </span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <span class="font-weight-medium pr-1" v-html="`${$tc('common.words.propertiesWithNumber', jobCreation.properties.length)}:`"/>
                        <v-tooltip
                            v-for="(property, propertyIndex) in jobCreation.properties"
                            :key="`property-${propertyIndex}`"
                            bottom
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                    style="cursor: pointer"
                                    class="my-2 mr-2"
                                    :color="getColors.primary"
                                    text-color="#fff"
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ property.value }}
                                </v-chip>
                            </template>
                            <span>
                                {{ property.text }}
                            </span>
                        </v-tooltip>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-card>
        <v-card-actions>
            <v-spacer/>
            <v-checkbox
                v-if="showPhotoCacheClearCheckbox()"
                ripple
                dense
                class="mr-3"
                v-model="photosCacheClear"
                :color="getColors.success"
                on-icon="mdi-checkbox-marked-outline"
            >
                <template v-slot:label>
                    <span
                        :style="'padding-top:2px;' + (photosCacheClear ? 'color:' + getColors.success : '')"
                        v-html="$t('jobCreator.stepper.step.execute.photosCacheClear')"
                    />
                </template>
            </v-checkbox>
            <v-checkbox
                ripple
                dense
                v-model="redirect"
                :color="getColors.success"
                on-icon="mdi-checkbox-marked-outline"
            >
                <template v-slot:label>
                    <span
                        :style="'padding-top:2px;' + (redirect ? 'color:' + getColors.success : '')"
                        v-html="$t('jobCreator.stepper.step.execute.redirectToQueue')"
                    />
                </template>
            </v-checkbox>
        </v-card-actions>
    </v-card>
</template>

<script>
import TTCustom from '@/components/Tooltips/TTFreierText.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'SummaryCard',
    components: {
        TTCustom,
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getJobCreation',
        ]),
        redirect: {
            get() {
                return this.getJobCreation.redirect
            },
            set(newVal) {
                this.$store.commit('UPDATE_JOB_CREATION', { redirect: newVal })
            },
        },
        photosCacheClear: {
            get() {
                return this.getJobCreation.photosCacheClear
            },
            set(newVal) {
                this.$store.commit('UPDATE_JOB_CREATION', { photosCacheClear: newVal })
            },
        },
    },
    methods: {
        showPhotoCacheClearCheckbox() {
            const ssid = this.getJobCreation.ssid
            const jobName = this.getJobCreation.job

            return (jobName === 'sendPhotos' && (ssid === 10 || ssid === 18))
        }
    }
}
</script>
