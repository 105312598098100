var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"w-100 pl-4 pb-2 mb-4"},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('p',{staticClass:"display-1 text--primary",domProps:{"innerHTML":_vm._s(_vm.$t('jobCreator.heading'))}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('jobCreator.subheading'))}})]),_c('Dialog',{attrs:{"data":{
                title: _vm.$t('common.dialogs.jobCreator.heading'),
                text: ("" + (_vm.$t('common.dialogs.jobCreator.text', {state: _vm.getDialog.data.step, step: _vm.getDialog.data.stepDescription}))),
                replyAction: {
                    state: true,
                    btnText: _vm.$t('common.dialogs.jobCreator.yes')
                },
                cancelBtnText: _vm.$t('common.dialogs.jobCreator.no')
            }},on:{"replyAction":_vm.cancelJobCreation}}),(_vm.innerWidth > _vm.breakpoint)?_c((_vm.innerWidth > _vm.breakpoint ? 'StepperHorizontal' : 'StepperVertical'),{tag:"component",attrs:{"stepper-data":_vm.stepper},on:{"continue-action":_vm.continueAction}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }