<template>
    <div>
        <v-card class="elevation-0">
            <v-card-title class="pl-1">
                {{
                (channelIndex === null ? $t('jobCreator.stepper.step.channel.heading1') : $t('jobCreator.stepper.step.channel.heading2', { name: getPropertyChannels[channelIndex].ss_name }))
                }}
            </v-card-title>
            <div v-if="!getPropertyChannels.length" class="d-flex flex-wrap">
                <skeleton
                    class="ma-2"
                    v-for="i in 6"
                    :key="`skeleton-${i}`"
                    style="max-width: 180px;min-width: 180px"
                    type="card"
                    :elevation="2"
                />
            </div>
            <v-row v-else class="justify-start">
                <v-col v-for="(propertyChannel, index) in getPropertyChannels" :key="`propertyChannel-${index}`"
                       class="col-auto">
                    <v-card
                        height="210"
                        width="150"
                        class="d-flex flex-column ma-4 cursor-pointer"
                        elevation="4"
                        @click="setSelectedChannel(propertyChannel, index)"
                        @keyup.enter="setSelectedChannel(propertyChannel, index)"
                    >
                        <div class="d-flex justify-center align-center">
                            <v-img
                                max-width="75"
                                height="100"
                                contain
                                :src="require(`../../assets/images/portale/${propertyChannel.ss_id}.png`)"
                                :lazy-src="require(`../../assets/images/portale/${propertyChannel.ss_id}.png`)"
                            />
                        </div>
                        <v-divider/>
                        <div>
                            <v-card-text class="text-center subtitle-1 font-weight-medium">
                                {{ propertyChannel.ss_textid }}
                            </v-card-text>
                        </div>
                        <v-divider/>
                        <div class="fill-height d-flex justify-center align-center">
                            <v-icon
                                class="ma-0 pa-0"
                                v-if="channelIndex === index"
                                :color="getColors.success"
                            >
                                mdi-check-circle
                            </v-icon>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import Skeleton from '@/components/Allgemein/Skeleton.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'SelectChannelStep',
    components: { Skeleton },
    data() {
        return {
            channelIndex: null,
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getPropertyChannels',
            'getStep',
            'getJobCreation',
        ]),
    },
    watch: {
        '$store.state.jobCreation': function () {

            if (this.getStep === 1 && !this.getJobCreation.ssid && this.channelIndex !== null)
                this.channelIndex = null
        },
        '$store.state.step': function () {

            if (this.getStep === 1 && this.getJobCreation.ssid && this.channelIndex === null)
                this.channelIndex = this.findChannelIndex()
        },
    },
    methods: {
        findChannelIndex() {
            return this.getPropertyChannels.findIndex(elem => elem.ss_id === this.getJobCreation.ssid)
        },
        setSelectedChannel(propertyChannel, index) {

            logger.log(`this is selected propertyChannel: `, propertyChannel)

            this.$store.dispatch('resetProps', ['chips', 'channelProperties', 'jobCreation'])

            this.$store.commit('UPDATE_JOB_CREATION', {
                ssid: propertyChannel.ss_id,
                textid: propertyChannel.ss_textid,
                channelName: propertyChannel.ss_name,
            })

            this.channelIndex = index
        },
    },
    mounted() {
        if (this.getJobCreation.ssid && this.channelIndex === null)
            this.channelIndex = this.findChannelIndex()
    },
}
</script>
