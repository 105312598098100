<template>
    <div class="w-100 pl-4 pb-2 mb-4" v-resize="onResize">
        <v-card outlined>
            <v-card-text>
                <p class="display-1 text--primary" v-html="$t('jobCreator.heading')"></p>
                <p v-html="$t('jobCreator.subheading')"></p>
            </v-card-text>
            <Dialog
                :data="{
                    title: $t('common.dialogs.jobCreator.heading'),
                    text: `${$t('common.dialogs.jobCreator.text', {state: getDialog.data.step, step: getDialog.data.stepDescription})}`,
                    replyAction: {
                        state: true,
                        btnText: $t('common.dialogs.jobCreator.yes')
                    },
                    cancelBtnText: $t('common.dialogs.jobCreator.no')
                }"
                @replyAction="cancelJobCreation"
            />
            <component
                v-if="innerWidth > breakpoint"
                :is="(innerWidth > breakpoint ? 'StepperHorizontal' : 'StepperVertical')"
                :stepper-data="stepper"
                @continue-action="continueAction"
            />
        </v-card>
    </div>
</template>

<script>
import Dialog from '@/components/Allgemein/Dialog.vue'
import StepperHorizontal from '@/components/Allgemein/Stepper/StepperHorizontal.vue'
import StepperVertical from '@/components/Allgemein/Stepper/StepperVertikal.vue'
import QueueService from '@/services/queue.service'

import { mapGetters } from 'vuex'

const name = 'JobCreator'

export default {
    name: name,
    components: {
        StepperHorizontal,
        StepperVertical,
        Dialog,
    },
    metaInfo: {
        title: name,
    },
    sockets: {
        getJobs(data) {
            if (data.hasOwnProperty('jobs'))
                this.jobs = data.jobs
        },
    },
    data() {
        return {
            breakpoint: 990,
            innerWidth: 0,
            isMobile: false,
            fingerIcon: '👉',
            success: false,
            name: name,
            expanded: [],
            singleExpand: false,
            search: '',
            jobs: [],
        }
    },
    computed: {
        ...mapGetters([
            'getDialog',
            'getJobCreation',
            'getStep',
        ]),
        finger: {
            get() {
                return this.fingerIcon
            },
            set(newVal) {
                this.fingerIcon = newVal
            },
        },
        sstextid() {
            return this.getJobCreation.textid
        },
        stepper: function () {
            return [
                {
                    pos: 1,
                    title: this.$i18n.t('jobCreator.stepper.step.channel.title'),
                    component: 'SelectChannelStep',
                    requirementNext: () => {
                        return this.$store.getters.getJobCreation.ssid > 0
                    },
                    requirementCancel: () => {
                        return this.$store.getters.getJobCreation.ssid > 0
                    },
                    continueBtnText: () => {
                        return this.$i18n.t('jobCreator.stepper.step.channel.continue')
                    },
                    editable: () => {
                        return this.checkEditability(0)
                    },
                    completed: () => {
                        return this.checkStepCompletion(0)
                    },
                },
                {
                    pos: 2,
                    title: this.$i18n.t('jobCreator.stepper.step.member.title'),
                    component: 'SelectMembersStep',
                    requirementNext: () => {
                        const requirementStep1 = this.stepper[0].requirementNext()
                        const thisRequirement = Object.keys(this.$store.getters.getJobCreation.members).length > 0

                        return requirementStep1 && thisRequirement
                    },
                    requirementCancel: () => {
                        return this.$store.getters.getJobCreation.ssid > 0
                    },
                    continueBtnText: () => {
                        return this.$i18n.t('jobCreator.stepper.step.member.continue')
                    },
                    editable: () => {
                        return this.checkEditability(1)
                    },
                    completed: () => {
                        return this.checkStepCompletion(1)
                    },
                },
                {
                    pos: 3,
                    title: this.$i18n.t('jobCreator.stepper.step.property.title'),
                    component: 'SelectPropertiesStep',
                    requirementNext: () => {
                        const members = this.$store.getters.getJobCreation.members
                        let allHasProperies = false

                        for (let i in members) {
                            if (members.hasOwnProperty(i))
                                allHasProperies = (members[i].properties.length > 0)
                        }

                        const requirementStep1 = this.stepper[0].requirementNext()
                        const requirementStep2 = this.stepper[1].requirementNext()

                        return requirementStep1 && requirementStep2 && allHasProperies
                    },
                    requirementCancel: () => {
                        return this.$store.getters.getJobCreation.ssid > 0
                    },
                    continueBtnText: () => {
                        return this.$i18n.t('jobCreator.stepper.step.property.continue')
                    },
                    editable: () => {
                        return this.checkEditability(2)
                    },
                    completed: () => {
                        return this.checkStepCompletion(2)
                    },
                },
                {
                    pos: 4,
                    title: this.$i18n.t('jobCreator.stepper.step.job.title'),
                    component: 'SelectJobStep',
                    requirementNext: () => {
                        const requirementStep1 = this.stepper[0].requirementNext()
                        const requirementStep2 = this.stepper[1].requirementNext()
                        const requirementStep3 = this.stepper[2].requirementNext()
                        const thisRequirement = this.$store.getters.getJobCreation.job !== ''

                        return requirementStep1 && requirementStep2 && requirementStep3 && thisRequirement
                    },
                    requirementCancel: () => {
                        return this.$store.getters.getJobCreation.ssid > 0
                    },
                    continueBtnText: () => {
                        return this.$i18n.t('jobCreator.stepper.step.job.continue')
                    },
                    editable: () => {
                        return this.checkEditability(3)
                    },
                    completed: () => {
                        return this.checkStepCompletion(3)
                    },
                },
                {
                    pos: 5,
                    title: this.$i18n.t('jobCreator.stepper.step.execute.title'),
                    component: 'SummaryExecuteStep',
                    requirementNext: () => {
                        const requirementStep1 = this.stepper[0].requirementNext()
                        const requirementStep2 = this.stepper[1].requirementNext()
                        const requirementStep3 = this.stepper[2].requirementNext()
                        const requirementStep4 = this.stepper[3].requirementNext()
                        const thisRequirement = this.$store.getters.getJobCreation.job !== ''

                        return requirementStep1 && requirementStep2 && requirementStep3 && requirementStep4 && thisRequirement
                    },
                    requirementCancel: () => {
                        return this.$store.getters.getJobCreation.ssid > 0
                    },
                    continueBtnText: (pos) => {
                        if (pos === 5 && !Object.keys(this.getJobCreation.success).length) {
                            const members = this.$store.getters.getJobCreation.members
                            let count = 0

                            for (let i in members) {
                                if (members.hasOwnProperty(i)) {
                                    const member = members[i]
                                    count += member.properties.length
                                }
                            }

                            return this.$i18n.tc('jobCreator.stepper.step.execute.continue', count)
                        }

                        return this.$i18n.t('jobCreator.createNew')
                    },
                    editable: () => {
                        return this.checkEditability(4)
                    },
                    completed: () => {
                        return this.checkStepCompletion(4)
                    },
                },
            ]
        },
    },
    methods: {
        checkStepCompletion(index) {
            const step = this.stepper[index]
            return (this.getStep > step.pos && step.requirementNext()) || Object.keys(this.getJobCreation.success).length > 0
        },
        checkEditability(index) {
            const prevIndex = (index > 0 ? index - 1 : index)
            return this.stepper[prevIndex].requirementNext() && !Object.keys(this.getJobCreation.success).length
        },
        onResize() {
            this.innerWidth = window.innerWidth

            this.finger = '👉'

            if (this.innerWidth <= this.breakpoint)
                this.finger = '👇'
        },
        async continueAction(e, pos) {
            //Info: All steps before execute
            if (pos < 5)
                this.$store.commit('SET_STEP', (pos + 1))
            //Info: Step execute before executing
            else if (pos === 5 && !Object.keys(this.getJobCreation.success).length && !this.currentlyExecuting) {
                await this.executeJob()

                if (this.getJobCreation.redirect)
                    await this.$router.push({ path: '/jobs' })
            }
            //Info: Step execute after executing
            else if (pos === 5 && Object.keys(this.getJobCreation.success).length)
                this.cancelJobCreation()
        },
        cancelJobCreation() {
            this.$store.dispatch('resetProps', ['dialog', 'chips', 'jobCreation', 'channelMembers', 'channelProperties', 'loaders'])
            this.$store.commit('SET_STEP', 1)
        },
        async executeJob() {
            await this.$store.dispatch('setLoaders', { jobCreator: true })
            const jobs = this.getJobCreation
            const members = jobs.members

            const minimizedJobs = {
                ssid: jobs.ssid,
                textid: jobs.textid,
                job: jobs.job,
                photosCacheClear: jobs.photosCacheClear,
                members: {},
            }

            for (let i in members) {
                if (members.hasOwnProperty(i)) {
                    const member = members[i]
                    const memberid = i

                    minimizedJobs.members[memberid] = {
                        objids: [],
                    }

                    const properties = member.properties
                    for (let j in properties) {
                        if (properties.hasOwnProperty(j)) {
                            const property = properties[j]
                            const objid = property.value

                            minimizedJobs.members[memberid].objids.push(objid)
                        }
                    }
                }
            }

            const response = await QueueService.setJobCreation(minimizedJobs)

            this.success = true
            await this.$store.dispatch('setLoaders', { jobCreator: false })

            this.$store.commit('UPDATE_JOB_CREATION', {
                success: response,
            })
        },
    },
    async created() {
        this.onResize()
        await this.$store.dispatch('stopActingAsMember')
        await this.$store.dispatch('getAndSetPropertyChannels')
        await this.$store.dispatch('setLoaders', { jobCreator: false })
    },
    beforeRouteLeave(to, from, next) {
        this.$store.dispatch('resetProps', ['dialog', 'chips', 'channelMembers', 'propertyChannels', 'channelProperties', 'jobCreation', 'step', 'loaders'])
        next()
    },
    beforeRouteEnter(to, from, next) {
        next(async vm => vm.$store.commit('RESET_PROP', 'members'))
    },
}
</script>
