<template>
    <div>
        <v-card tile class="pa-4 mb-4" elevation="1">
            <v-card-title
                class="pl-0 font-weight-bold"
                v-html="`${memberFull.company} (${memberFull.id})`"
            />
            <div class="d-flex align-end">
                <div style="width: 80%">
                    <v-combobox
                        v-model="chips"
                        :ref="`chipsField${componentIndex}`"
                        :items="items"
                        :search-input.sync="search"
                        :custom-filter="customFilter"
                        @change="search = ''"
                        :loading="getLoaders.channelpropertiesmultiplemember"
                        :color="getColors.primary"
                        :item-color="getColors.primary"
                        :placeholder="placeholderText"
                        :label="labelText"
                        @focus="labelText = ''"
                        @blur="handleLabelText"
                        chips
                        deletable-chips
                        clearable
                        @click:clear="clearAllChips"
                        multiple
                    >
                        <template v-slot:selection="data">
                            <v-chip
                                v-if="data.item.value"
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                :color="getColors.primary"
                                text-color="white"
                                @click:close="removeChip(data.item)"
                                close
                            >
                                <span>
                                    {{ JSON.parse(JSON.stringify(data.item)).value }}
                                </span>
                            </v-chip>
                        </template>
                    </v-combobox>
                </div>
                <div style="width: 20%" class="d-flex align-center justify-end">
                    <v-checkbox
                        ripple
                        dense
                        v-model="allProperties"
                        :disabled="allProperties"
                        :color="getColors.success"
                        @change="selectAllProperties"
                        :label="$t('jobCreator.stepper.step.property.allProperties')"
                        on-icon="mdi-checkbox-marked-outline"
                    />
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'PropertiesCombobox',
    props: {
        componentIndex: Number,
        memberidIndex: Number,
        member: Object,
    },
    data() {
        return {
            channelpropertiesmultiplemember: false,
            allProperties: false,
            labelText: '',
            placeholderText: '',
            items: [],
            search: null,
            memberFull: {},
            memberid: this.memberidIndex,
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getMembers',
            'getLoaders',
            'getJobCreation',
            'getStep',
            'getChips',
            'getChannelProperties',
        ]),
        chips: {
            get: function () {
                const chips = this.getChips[this.memberid]

                if (!chips.properties.length)
                    return []

                const chipsArr = []

                for (let i in chips.properties) {
                    if (chips.properties.hasOwnProperty(i)) {
                        const property = chips.properties[i]

                        if (!chipsArr.includes(property))
                            chipsArr.push(property)
                    }
                }

                this.handleAllPropertiesCheckbox()

                return chipsArr
            },
            set: function (selectedPropertiesArr) {

                const properties = []

                logger.log(`this is selectedPropertiesArr; `, selectedPropertiesArr)

                selectedPropertiesArr.forEach(val => {
                    if (!properties.includes(val)) {
                        if (typeof val === 'string' && val.includes('#')) {
                            val = val.replace('#', '')
                            val = this.items.find(elem => elem.value === parseInt(val))
                        }

                        if (typeof val === 'object' && val.text !== undefined && val.value !== undefined)
                            properties.push(val)
                    }
                })

                if (properties.length) {
                    this.$store.commit('SET_CHIPS', { memberid: this.memberid, properties: properties })

                    this.$store.commit('UPDATE_JOB_CREATION', {
                        memberid: this.memberid,
                        properties: properties,
                    })
                }

                this.handleAllPropertiesCheckbox()
            },
        },
    },
    watch: {
        '$store.state.channelProperties': function () {
            this.createComboboxItems()
        },
        '$store.state.chips': function () {
            if (!Object.keys(this.getChips).length)
                this.clearAllChips()
        },
        '$store.state.step': function () {
            if (this.getStep === 3 && this.$refs.chipsField0)
                this.$refs.chipsField0.focus()
        },
    },
    methods: {
        customFilter(value, search, item) {

            const keys = [
                'obj_titel',
                'obje_objid',
                'obje_objnummer',
                'obje_externid',
                'obje_parameter1',
                'obje_parameter2',
                'obje_parameter3',
                'obje_parameter4',
                'obje_parameter5',
                'obj2ms_hotelid',
                'obj2ms_categoryid',
                'obj2ms_listingid',
                'obj2ms_importid',
                'obj2ms_rateid',
            ]

            return utils.customFilter(keys, value, search, item)
        },
        handleAllPropertiesCheckbox() {
            //logger.log(`this is length of channleProperties: ${this.getChannelProperties[this.memberid].length}, length of chips properties: ${this.getChips[this.memberid].properties.length}`)

            if (this.getChips.hasOwnProperty(this.memberid) && this.getChannelProperties.hasOwnProperty(this.memberid))
                this.allProperties = (this.getChips[this.memberid].properties.length === this.getChannelProperties[this.memberid].length)
        },
        handleLabelText() {
            if (!this.getChips[this.memberid].properties.length)
                this.labelText = this.placeholderText
        },
        findMemberInMembers() {
            this.memberFull = this.getMembers.find(elem => elem.id === this.memberid)
        },
        createComboboxItems() {
            this.getChannelProperties[this.memberid].forEach(val => {
                this.items.push({
                    text: `#${ val.obje_objid } - ${ val.obj_titel }${ this.getChannelSpecificPropertyId(val) }`,
                    value: val.obje_objid,
                })
            })

            this.placeholderText = this.$i18n.tc('jobCreator.stepper.step.property.comboboxPlaceholder', this.getChannelProperties[this.memberid].length, { name: this.getJobCreation.textid })
            this.handleLabelText()
        },
        getChannelSpecificPropertyId(val) {
            let id = ''

            switch (this.getJobCreation.ssid) {
                case 9:
                    if (val.obje_parameter2 || val.obj2ms_hotelid)
                        id = (val.obje_parameter2 ? val.obje_parameter2 : val.obj2ms_hotelid)
                    break

                case 10:
                    if (val.obje_parameter3 || val.obj2ms_hotelid)
                        id = (val.obje_parameter3 ? val.obje_parameter3 : val.obj2ms_hotelid)
                    break

                case 24:
                    if (val.obj2ms_listingid)
                        id = val.obj2ms_listingid
                    break

                case 27:
                    if (val.obje_parameter3)
                        id = val.obje_parameter3
                    break

                case 31:
                    if (val.obj2ms_hotelid)
                        id = val.obj2ms_hotelid
                    break
            }

            if (id)
                id = ` (${ id })`

            return id
        },
        clearAllChips() {
            this.$store.commit('UPDATE_JOB_CREATION', {
                memberid: this.memberid,
                properties: [],
            })

            this.$store.commit('SET_CHIPS', { memberid: this.memberid, properties: [] })
            this.handleAllPropertiesCheckbox()
        },
        async selectAllProperties() {
            this.channelpropertiesmultiplemember = true

            let properties = []

            this.items.forEach(item => {
                if (!properties.includes(item))
                    properties.push(item)
            })

            this.$store.commit('UPDATE_JOB_CREATION', {
                memberid: this.memberid,
                properties: properties,
            })

            this.$store.commit('SET_CHIPS', { memberid: this.memberid, properties: properties })

            this.channelpropertiesmultiplemember = false

            properties = []
        },
        removeChip(item) {
            this.$store.commit('REMOVE_FROM_CHIPS', { memberid: this.memberid, objid: item.value })
            this.handleAllPropertiesCheckbox()
        },
    },
    mounted() {
        if (this.memberid && this.getMembers.length)
            this.findMemberInMembers()

        if (this.memberid && Object.keys(this.getChannelProperties).length && this.getChannelProperties.hasOwnProperty(this.memberid))
            this.createComboboxItems()
    },
}
</script>
