export default {
    jobs_by_channel: [
        {
            ssid: 9,
            jobs: [
                {
                    name: 'sendBaseApartment',
                    description: 'Sends the master data of the property',
                },
                {
                    name: 'sendPhotos',
                    description: 'Sends the photos of the property',
                },
                {
                    name: 'sendAvailability',
                    description: 'Sends the availability of the property',
                },
                {
                    name: 'sendPrices',
                    description: 'Sends the prices of the property',
                },
                {
                    name: 'sendSpecialOffer',
                    description: 'Sends special offers of the property',
                },
                {
                    name: 'closeProperty',
                    description: 'Closes the property on TEXTID_REPLACE',
                },
            ],
        },
        {
            ssid: 10,
            jobs: [
                {
                    name: 'sendBaseApartment',
                    description: 'Sends the master data of the property',
                },
                {
                    name: 'sendPhotos',
                    description: 'Sends the photos of the property',
                },
                {
                    name: 'sendAvailability',
                    description: 'Sends the availability of the property',
                },
                {
                    name: 'sendPrices',
                    description: 'Sends the prices of the property',
                },
                {
                    name: 'sendSpecialOffer',
                    description: 'Sends special offers of the property',
                },
                {
                    name: 'closeProperty',
                    description: 'Closes the property on TEXTID_REPLACE',
                },
                {
                    name: 'sendClients',
                    description: 'Sends clients for the member',
                },
            ],
        },
        {
            ssid: 18,
            jobs: [
                {
                    name: 'sendBaseApartment',
                    description: 'Sends the master data of the property',
                },
                {
                    name: 'sendPhotos',
                    description: 'Sends the photos of the property',
                },
                {
                    name: 'sendAvailability',
                    description: 'Sends the availability of the property',
                },
                {
                    name: 'sendPrices',
                    description: 'Sends the prices of the property',
                },
                /*{
                    name: 'sendSpecialOffer',
                    description: 'Sends special offers of the property',
                },*/
                {
                    name: 'closeProperty',
                    description: 'Closes the property on TEXTID_REPLACE',
                },
            ],
        },
        {
            ssid: 21,
            jobs: [
                {
                    name: 'sendOccupancies',
                    description: 'Syncs the occupancies of the property',
                },
            ],
        },
        {
            ssid: 24,
            jobs: [
                {
                    name: 'sendBaseApartment',
                    description: 'Sends the master data of the property',
                },
                {
                    name: 'sendPhotos',
                    description: 'Sends the photos of the property',
                },
                {
                    name: 'sendAvailability',
                    description: 'Sends the availability of the property',
                },
                {
                    name: 'sendPrices',
                    description: 'Sends the prices of the property',
                },
                {
                    name: 'closeProperty',
                    description: 'Closes the property on TEXTID_REPLACE',
                },
            ],
        },
        {
            ssid: 27,
            jobs: [
                {
                    name: 'sendAvailability',
                    description: 'Sends the availability of the property',
                },
                {
                    name: 'sendPrices',
                    description: 'Sends the prices of the property',
                },
                {
                    name: 'closeProperty',
                    description: 'Closes the property on TEXTID_REPLACE',
                },
            ],
        },
        {
            ssid: 29,
            jobs: [
                {
                    name: 'sendAvailability',
                    description: 'Sends the availability of the property',
                },
                {
                    name: 'sendPrices',
                    description: 'Sends the prices of the property',
                },
                {
                    name: 'closeProperty',
                    description: 'Closes the property on TEXTID_REPLACE',
                },
            ],
        },
        {
            ssid: 31,
            jobs: [
                {
                    name: 'sendProperty',
                    description: 'Sends the master data of the property',
                },
                {
                    name: 'sendOccupancies',
                    description: 'Syncs the occupancies of the property',
                },
                {
                    name: 'sendReviews',
                    description: 'Sends the reviews of the property',
                },
                {
                    name: 'sendServices',
                    description: 'Sends the services of the property',
                },
                {
                    name: 'sendPropertyImages',
                    description: 'Sends the photos of the property',
                },
                {
                    name: 'sendFloorplanImages',
                    description: 'Sends the floorplan images of the property',
                },
                {
                    name: 'sendPrices',
                    description: 'Sends the prices of the property',
                },
                {
                    name: 'sendSurcharges',
                    description: 'Sends the surcharges of the property',
                },
                {
                    name: 'sendSpecialOffer',
                    description: 'Sends special offers of the property',
                },
                {
                    name: 'sendClients',
                    description: 'Sends clients for the member',
                },
                {
                    name: 'sendTarifzonen',
                    description: 'Sends the tariff zones of the member',
                },
            ],
        },
    ],
}