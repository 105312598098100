<template>
    <div>
        <summary-card v-if="!Object.keys(this.getJobCreation.success).length"/>
        <success-card v-else/>
    </div>
</template>

<script>
import SuccessCard from '@/components/Uebertragungserstellungsassistent/ErgebnisErstellterAufgaben.vue'
import SummaryCard from '@/components/Uebertragungserstellungsassistent/ZusammenfassungErstellterJobs.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'SelectJobStep',
    components: {
        SuccessCard,
        SummaryCard,
    },
    data() {
        return {
            success: false,
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getJobCreation',
        ]),
    },
}
</script>
