<template>
    <div>
        <v-card elevation="0">
            <v-card-title
                class="pl-0"
                v-html="(!getJobCreation.job ? $t('jobCreator.stepper.step.job.heading1', {name: getJobCreation.textid }) : $t('jobCreator.stepper.step.job.heading2', { job: getJobCreation.job, name: getJobCreation.textid }))"
            />
            <v-row class="justify-start">
                <v-col
                    v-for="(channelJob, index) in channelJobs.jobs"
                    :key="`propertyChannel-${index}`"
                    class="col-auto pl-0"
                >
                    <v-card
                        min-height="100"
                        width="200"
                        class="ma-4"
                        elevation="4"
                        @click="setSelectedJob(channelJob)"
                        @keyup.enter="setSelectedJob(channelJob)"
                    >
                        <v-card-text class="text-center subtitle-1 font-weight-medium">
                            {{ channelJob.name }}
                        </v-card-text>
                        <v-divider/>
                        <div class="pa-0">
                            <v-btn
                                block
                                plain
                                class="ma-0"
                                v-if="getJobCreation.job === channelJob.name"
                                :color="getColors.success"
                            >
                                <v-icon>
                                    mdi-check-circle
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import jobsModel from '@/models/jobs.model'
import { mapGetters } from 'vuex'

export default {
    name: 'SelectJobStep',
    data() {
        return {
            channelJobs: [],
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getStep',
            'getJobCreation',
        ]),
    },
    watch: {
        '$store.state.step': function () {
            this.setEnumJobs()
        },
    },
    methods: {
        setEnumJobs() {
            if (this.getStep === 4 && this.getJobCreation.ssid)
                this.channelJobs = jobsModel.jobs_by_channel.find(elem => elem.ssid === this.getJobCreation.ssid)
        },
        setSelectedJob(job) {

            this.$store.commit('UPDATE_JOB_CREATION', {
                job: job.name,
            })
        },
    },
    mounted() {
        this.setEnumJobs()
    },
}
</script>
